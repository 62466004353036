import { useEffect, useState } from 'react';
import CodeMirror, { basicSetup } from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { Code, Flex, IconButton, Text, Box } from '@radix-ui/themes';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyIcon } from '@radix-ui/react-icons';
import { CopyButton } from './components/CopyButton';

export interface CodeImplementationExample {
  websiteKey: string;
}

export const CodeImplementationExample = ({
  websiteKey,
}: CodeImplementationExample) => {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');

  useEffect(() => {
    if (websiteKey) {
      setValue1(`<iframe
    id="dm-iframe"
    src="https://embed.dynamicmockups.com"
    style="width: 100%; height: 90vh"
></iframe>`);
      setValue2(`<script
    src="https://cdn.jsdelivr.net/npm/@dynamic-mockups/mockup-editor-sdk@latest/dist/index.js"
></script>`);
      setValue3(`<script>
    document.addEventListener("DOMContentLoaded", function () {
      DynamicMockups.initDynamicMockupsIframe({
        iframeId: "dm-iframe",
        data: { "x-website-key": "${websiteKey}" },
        mode: "download",
      });
    });
</script>`);
    }
  }, [websiteKey]);

  return (
    <Flex direction="column" gap={'3'}>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>
          1. Copy the code below and paste it into the <Code>body</Code> section
          of your webpage where you want it to appear.
        </Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value1} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value1}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[
              javascript({
                jsx: true,
                typescript: true,
              }),
            ]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>
          2. Copy the code below and paste it into the <Code>head</Code> section
          of your webpage where you want it to appear, or place it just before
          the script mentioned in Step 3. It is important to ensure this script
          loads before the script from Step 3.
        </Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value2} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value2}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[javascript({ jsx: true, typescript: true })]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>
          3. Copy the code below and paste it just before the closing{' '}
          <Code>body</Code> tag of your webpage.
        </Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value3} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value3}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[javascript({ jsx: true, typescript: true })]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
