import { useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { Box, Code, Flex, Text } from '@radix-ui/themes';
import { CopyButton } from './components/CopyButton';

export interface CodeImplementationExample {
  websiteKey: string;
}

export const CodeImplementationExampleReact = ({
  websiteKey,
}: CodeImplementationExample) => {
  const [value1, setValue1] = useState('');
  const [value12, setValue12] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');

  useEffect(() => {
    if (websiteKey) {
      setValue1(`npm i @dynamic-mockups/mockup-editor-sdk@latest`);
      setValue12(`yarn add @dynamic-mockups/mockup-editor-sdk@latest`);
      setValue2(
        `import { initDynamicMockupsIframe } from "@dynamic-mockups/mockup-editor-sdk";`
      );
      setValue3(`useEffect(() => {
  initDynamicMockupsIframe({
    iframeId: "dm-iframe",
    data: { "x-website-key": "${websiteKey}" },
    mode: "download",
  });
}, []);`);
      setValue4(
        `<iframe
  id="dm-iframe"
  src="https://embed.dynamicmockups.com"
  style="width: 100%; height: 90vh"
></iframe>`
      );
    }
  }, [websiteKey]);

  return (
    <Flex direction="column" gap={'3'}>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>1. Install Mockup Editor SDK via npm or yarn.</Text>
        <Flex direction={'column'}>
          <Box style={{ position: 'relative' }}>
            <CopyButton text={value1} />
            <CodeMirror
              className="code-editor-wrapper"
              value={value1}
              height="100%"
              width="100%"
              readOnly
              basicSetup={{
                highlightActiveLine: false,
                lineNumbers: false,
                highlightSpecialChars: false,
                highlightActiveLineGutter: false,
                highlightSelectionMatches: false,
              }}
              extensions={[javascript({ jsx: true, typescript: true })]}
              theme={vscodeDark}
            />
          </Box>
          <Text size={'2'}>or</Text>
          <Box style={{ position: 'relative' }}>
            <CopyButton text={value12} />
            <CodeMirror
              className="code-editor-wrapper"
              value={value12}
              height="100%"
              width="100%"
              readOnly
              basicSetup={{
                highlightActiveLine: false,
                lineNumbers: false,
                highlightSpecialChars: false,
                highlightActiveLineGutter: false,
                highlightSelectionMatches: false,
              }}
              extensions={[javascript({ jsx: true, typescript: true })]}
              theme={vscodeDark}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>2. Import installed package.</Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value2} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value2}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[javascript({ jsx: true, typescript: true })]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>
          3. Initialize iframe by using <Code>initDynamicMockupsIframe</Code>{' '}
          function.
        </Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value3} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value3}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[javascript({ jsx: true, typescript: true })]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
      <Flex direction="column" gap={'1'}>
        <Text size={'2'}>4. Add iframe code where you want it to appear.</Text>
        <Box style={{ position: 'relative' }}>
          <CopyButton text={value4} />
          <CodeMirror
            className="code-editor-wrapper"
            value={value4}
            height="100%"
            width="100%"
            readOnly
            basicSetup={{
              highlightActiveLine: false,
              lineNumbers: false,
              highlightSpecialChars: false,
              highlightActiveLineGutter: false,
              highlightSelectionMatches: false,
            }}
            extensions={[javascript({ jsx: true, typescript: true })]}
            theme={vscodeDark}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
