import useImageToBase64 from '@/hooks/useImageToBase64';
import { TrashIcon, ImageIcon } from '@radix-ui/react-icons';
import {
  Box,
  Flex,
  Tooltip,
  IconButton,
  Button,
  Popover,
  Switch,
  Text,
  Separator,
} from '@radix-ui/themes';
import { useState, useRef, useEffect } from 'react';
import './add-design.scss';
import Chrome from '@uiw/react-color-chrome';
import { MockupEditorIframeIntegration } from '@/redux/slices/types';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IIntegrationConfig {
  editedIntegration?: MockupEditorIframeIntegration;
  setData?: (data: Partial<MockupEditorIframeIntegration>) => void;
}

export const IntegrationConfig = ({
  editedIntegration,
  setData,
}: IIntegrationConfig) => {
  const [hex, setHex] = useState(editedIntegration?.config?.color || '#0190ff');
  const [darkThemeMode, setDarkThemeMode] = useState<boolean>(
    editedIntegration?.config?.dark_mode || false
  );
  const [whitelabeled, setWhitelabeled] = useState<boolean>(
    typeof editedIntegration?.config?.whitelabel === 'boolean'
      ? editedIntegration?.config?.whitelabel
      : true
  );
  const [design, setDesign] = useState<File | string>(
    editedIntegration?.config?.logo || null!
  );
  const [designBase64, setDesignBase64] = useState<string>('');
  const [designName, setDesignName] = useState<string>('');

  const logoRef = useRef<HTMLInputElement>(null);

  const showEmbedMockupEditorConfig = useFeatureFlagEnabled(
    'embed-mockup-editor-config'
  );

  useEffect(() => {
    setData?.({
      config: {
        color: hex,
        dark_mode: darkThemeMode,
        whitelabel: whitelabeled,
        logo: (designBase64 as string) || (design as string),
      },
    });
  }, [whitelabeled, design, hex, darkThemeMode]);

  useEffect(() => {
    if (typeof design === 'string') {
      setDesignName(design.substring(design.lastIndexOf('/') + 1));
    }
  }, [design]);

  const handleAddedDesign = () => {
    logoRef?.current?.click();
  };
  const { convertImageToBase64 } = useImageToBase64();

  const onDesignUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File;
    const base64 = await convertImageToBase64(file);
    setDesignBase64(base64);
    setDesignName(file.name);
    setDesign(file);
  };

  const removeAddedLogo = () => {
    setDesignBase64('');
    setDesignName('');
    setDesign(null!);
  };

  return (
    <Box>
      {showEmbedMockupEditorConfig && (
        <Flex direction="column" gap="3">
          <Separator my="1" size="4" />
          <Flex direction={'column'} gap={'1'}>
            <Text size={'1'}>Logo</Text>
            <Flex
              direction={'column'}
              align={'center'}
              width={'100%'}
              className="add-design-wrapper"
            >
              {design ? (
                <Flex
                  className="design-file-box"
                  align={'center'}
                  justify={'between'}
                  p={'2'}
                  pr={'3'}
                  width={'100%'}
                  height={'32px'}
                >
                  <Flex align={'center'} gap={'2'}>
                    <img src={`${designBase64 || design}`} />
                    <Tooltip content={designName}>
                      <Text
                        className="design-name"
                        size={'1'}
                        weight={'regular'}
                        color="gray"
                      >
                        {designName}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <IconButton
                    variant={'ghost'}
                    size={'2'}
                    color="gray"
                    onClick={() => removeAddedLogo()}
                    type="button"
                  >
                    <TrashIcon width="16px" height="16px" />
                  </IconButton>
                </Flex>
              ) : (
                <Button
                  className="add-design-button"
                  variant="surface"
                  color="gray"
                  onClick={() => handleAddedDesign()}
                  type="button"
                >
                  <ImageIcon width={'16px'} height={'16px'} />
                </Button>
              )}

              <Flex direction={'column'} width={'100%'}>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .webp"
                  onChange={(event) => onDesignUpload(event)}
                  onClick={(event) => {
                    (event.target as any).value = null;
                  }}
                  ref={logoRef}
                  style={{ display: 'none' }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex direction={'column'}>
            <label>
              <Flex align={'center'} gap={'3'}>
                <Text size={'1'}>Primary color</Text>
                <Popover.Root>
                  <Popover.Trigger>
                    <IconButton variant="ghost">
                      <Box
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '4px',
                          backgroundColor: hex,
                        }}
                      ></Box>
                    </IconButton>
                  </Popover.Trigger>
                  <Popover.Content
                    className="color-picker-popover"
                    side="right"
                    sideOffset={8}
                  >
                    <Chrome
                      color={hex}
                      showAlpha={false}
                      inputType={'hexa' as any}
                      onChange={(color) => {
                        setHex(color.hex);
                      }}
                    />
                  </Popover.Content>
                </Popover.Root>
              </Flex>
            </label>
          </Flex>
          <Flex direction={'column'}>
            <label>
              <Flex align={'center'} gap={'2'}>
                <Text size={'1'}>Dark mode</Text>
                <Switch
                  defaultChecked={darkThemeMode}
                  onCheckedChange={(checked) => setDarkThemeMode(checked)}
                />
              </Flex>
            </label>
          </Flex>
          <Flex direction={'column'}>
            <label>
              <Flex align={'center'} gap={'2'}>
                <Text size={'1'}>"Powered by” footer</Text>
                <Switch
                  defaultChecked={whitelabeled}
                  onCheckedChange={(checked) => setWhitelabeled(checked)}
                />
              </Flex>
            </label>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
