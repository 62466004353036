import './view-wrapper.scss';
import { DesignsUploader } from '../DesignsUploader';
import { Flex, IconButton, ScrollArea, Tooltip } from '@radix-ui/themes';
import Uppy from '@uppy/core';
import { useRef, useState } from 'react';
import { Cross1Icon, TrashIcon } from '@radix-ui/react-icons';
import { SelectedDesignsOptionsBar } from './SelectedDesignsOptionsBar';
import { DeleteDesign } from './DeleteDesign';
import { useAppSelector } from '@/hooks';

export const ViewWrapper = ({
  setUppyDashboardInstance,
  handleUploadClick,
}: {
  setUppyDashboardInstance: (
    uppy: Uppy<Record<string, unknown>, Record<string, unknown>>
  ) => void;
  handleUploadClick?: () => void;
}) => {
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const [selectedDesigns, setSelectedDesigns] = useState<number[]>([]);
  const deleteDesignsDialog = useRef();

  return (
    <>
      <DeleteDesign
        ref={deleteDesignsDialog}
        setSelectedDesigns={setSelectedDesigns}
      />
      <SelectedDesignsOptionsBar
        selectedDesigns={selectedDesigns}
        setSelectedDesigns={setSelectedDesigns}
        deleteDesignsDialog={deleteDesignsDialog}
      />
      <ScrollArea
        type="hover"
        scrollbars="vertical"
        style={{
          height: `calc(100vh - 101px ${showNotificationBar ? '- 50px' : ''})`,
          maxWidth: 'calc(100% - 2px)',
          position: 'relative',
        }}
        className={`scroll-area-wrapper`}
        id="scroll-container"
      >
        <DesignsUploader
          selectDesign={() => undefined}
          setUppyDashboardInstance={setUppyDashboardInstance}
          handleUploadClick={handleUploadClick}
          setSelectedDesigns={setSelectedDesigns}
          selectedDesigns={selectedDesigns}
          isDesignsLibrary={true}
          deleteDesignsDialog={deleteDesignsDialog}
        />
      </ScrollArea>
    </>
  );
};
