import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { MockupEditorIframeIntegration } from '@/redux/slices/types';
import { DataModel, DataModelArray } from './types';

export const mockupEditorEmbedApi = createApi({
  reducerPath: 'mockupEditorEmbedApi',
  baseQuery: baseQuery,
  tagTypes: ['getMockupEditorIframeIntegrations'],
  endpoints: (builder) => ({
    getMockupEditorIframeIntegrations: builder.query<
      DataModelArray<MockupEditorIframeIntegration>,
      void
    >({
      query: () => `mockup-editor-iframe-integrations`,
      providesTags: ['getMockupEditorIframeIntegrations'],
    }),
    createMockupEditorIframeIntegration: builder.mutation<
      DataModel<MockupEditorIframeIntegration>,
      {
        integration_name: string;
        whitelisted_domain: string;
        config?: {
          color: string;
          dark_mode: boolean;
          whitelabel: boolean;
          logo: string | null;
        };
      }
    >({
      query: (payload) => ({
        url: `mockup-editor-iframe-integrations`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['getMockupEditorIframeIntegrations'],
    }),
    updateMockupEditorIframeIntegration: builder.mutation<
      DataModel<MockupEditorIframeIntegration>,
      Partial<MockupEditorIframeIntegration>
    >({
      query: (payload) => ({
        url: `mockup-editor-iframe-integrations/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['getMockupEditorIframeIntegrations'],
    }),
    deleteMockupEditorIframeIntegration: builder.mutation<void, { id: number }>(
      {
        query: ({ id }) => ({
          url: `mockup-editor-iframe-integrations/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['getMockupEditorIframeIntegrations'],
      }
    ),
    updateMockupEditorIframeIntegrationConfig: builder.mutation<
      DataModel<MockupEditorIframeIntegration>,
      {
        mockup_editor_iframe_integration_id: number;
        config: {
          color: string;
          dark_mode: boolean;
          whitelabel: boolean;
          logo: string | null | undefined;
        };
      }
    >({
      query: (payload) => ({
        url: `mockup-editor-iframe-configs`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['getMockupEditorIframeIntegrations'],
    }),
  }),
});

export const {} = mockupEditorEmbedApi;
