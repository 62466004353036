import { useState } from 'react';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import CopyToClipboard from 'react-copy-to-clipboard';

interface ICopyButton {
  text: string;
}

export const CopyButton = ({ text }: ICopyButton) => {
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const handleCopy = () => {
    setShowCheckIcon(true);
    setTimeout(() => setShowCheckIcon(false), 1500);
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <IconButton
        style={{
          position: 'absolute',
          top: '1px',
          right: '1px',
          zIndex: '2000000',
        }}
        size={'1'}
        variant="solid"
        color="gray"
      >
        {showCheckIcon ? <CheckIcon /> : <CopyIcon />}
      </IconButton>
    </CopyToClipboard>
  );
};
