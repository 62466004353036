import './view-wrapper.scss';
import { Text, ScrollArea, Flex, Badge } from '@radix-ui/themes';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { useAppSelector } from '@/hooks';
import { paymentsApi } from '@/services/payments';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { formatDate } from '@/helpers';

export const Subscription = () => {
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const userRendersData = paymentsApi.useGetUserRendersQuery();
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const goToBillingPortal = () => {
    window.open(
      `https://billing.stripe.com/p/login/cN2dU7f840IgafmaEE?prefilled_email=${currentUser?.email}`,
      '_blank'
    );
  };

  const getUserBillingStatus = () => {
    if (isAppPro) {
      if (currentUser?.subscription_trial.status === 'active') {
        return <Badge color="blue">TRIAL</Badge>;
      }
      if (currentUser?.subscription_trial.status === 'cancelled') {
        if (userRendersData.data?.data[0]?.interval === 'free') {
          return <Badge color="orange">TRIAL CANCELLED</Badge>;
        } else {
          return <Badge color="amber">PREMIUM</Badge>;
        }
      }
      if (
        currentUser?.subscription_trial.status === 'completed' ||
        currentUser?.subscription_trial.status === 'inactive'
      ) {
        if (userRendersData.data?.data[0]?.interval === 'free') {
          return <Badge color="amber">PRO</Badge>;
        } else {
          return <Badge color="purple">PREMIUM</Badge>;
        }
      }
    } else {
      return <Badge color="blue">FREE</Badge>;
    }
    return <Badge color="blue">FREE</Badge>;
  };

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{
        height: `calc(100vh - 133px ${showNotificationBar ? '- 50px' : ''})`,
      }}
    >
      <Flex
        className="subscription-wrapper"
        direction={'column'}
        gap={'3'}
        px={'6'}
      >
        <Flex className="name-wrapper" direction={'column'} gap={'2'}>
          <Text className="name" weight={'regular'} size={'2'}>
            Subscription type: {getUserBillingStatus()}
          </Text>
          {(currentUser?.subscription_trial.status === 'active' ||
            currentUser?.subscription_trial.status === 'cancelled') &&
            userRendersData.data?.data[0]?.plan === 'free' &&
            isAppPro && (
              <Flex direction={'column'}>
                <Text weight={'regular'} size={'2'}>
                  Trial period start:{' '}
                  {formatDate(
                    currentUser?.subscription_trial.trial_starts_at || ''
                  )}
                </Text>
                <Text weight={'regular'} size={'2'}>
                  Trial period end:{' '}
                  {formatDate(
                    currentUser?.subscription_trial.trial_ends_at || ''
                  )}
                </Text>
              </Flex>
            )}
        </Flex>
        <Flex align={'center'} gap={'1'}>
          <Text
            color="blue"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
            onClick={() => goToBillingPortal()}
          >
            Billing Dashboard <ExternalLinkIcon style={{ cursor: 'pointer' }} />
          </Text>
        </Flex>
      </Flex>
    </ScrollArea>
  );
};
