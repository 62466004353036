import './collection.scss';
import { Helmet } from 'react-helmet-async';
import { Flex, Grid } from '@radix-ui/themes';
import { Editor } from './components/editor/Editor';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import { useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export const Collection = () => {
  const navigate = useNavigate();
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  // useEffect(() => {
  //   if (mockup?.uuid) {
  //     navigate(`/mockup/${mockup.uuid}`);
  //   }
  // }, [mockup]);

  const getColumnsNumber = () => {
    switch (activeView) {
      case 'create-ai-background':
        return '6fr 18fr';
      case 'preview':
        return '8fr 20fr';
      case 'update-asset':
        return '7fr 12fr';
      case 'update-color':
        return '4fr 15fr';
      default:
        return '8fr 20fr';
    }
  };

  return (
    <>
      {isMobile && (
        <Flex direction={'column'} className="collection-wrapper">
          <Helmet>
            <title>Dynamic Mockups | Mockup</title>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          <ViewWrapper />
          <Editor />
        </Flex>
      )}
      {!isMobile && (
        <Grid
          columns={getColumnsNumber()}
          rows={'1fr'}
          className="collection-wrapper"
          style={{
            height: `calc(100vh - 64px ${
              showNotificationBar ? '- 46px' : '+ 0px'
            })`,
          }}
        >
          <Helmet>
            <title>Dynamic Mockups | Collection</title>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          <Editor />
          <ViewWrapper />
        </Grid>
      )}
    </>
  );
};
