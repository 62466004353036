import { useState } from "react";

export const useImageToBase64 = () => {
  const [base64, setBase64] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const convertImageToBase64 = (file: File): Promise<string> => {
    setLoading(true);
    setError(null);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        setBase64(result);
        setLoading(false);
        resolve(result);
      };
      reader.onerror = () => {
        const errorMessage = "Failed to convert image to Base64";
        setError(errorMessage);
        setLoading(false);
        reject(errorMessage);
      };
      reader.readAsDataURL(file);
    });
  };

  return { base64, error, loading, convertImageToBase64 };
};

export default useImageToBase64;
