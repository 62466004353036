import { Helmet } from 'react-helmet-async';
import './shop.scss';
import {
  Box,
  Button,
  Flex,
  ScrollArea,
  Tabs,
  Text,
  Link,
  AlertDialog,
  Badge,
} from '@radix-ui/themes';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { LeftSideMenu } from '../left-side-menu/LeftSideMenu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { pageSlug } from '../../Workspace';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import { useAppDispatch, useAppSelector } from '@/hooks';
import posthog from 'posthog-js';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IShop {
  name: string;
  icon: JSX.Element;
  posthogProp: string;
}

export const Shop = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const [activeSection, setActiveSection] = useState<pageSlug>('integrations');
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );

  shopsIntegrationsApi.useGetActiveIntegrationDetailsQuery(
    {
      id: parseInt(shopId || ''),
    },
    {
      skip: !shopId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [disconnnectShopIntegration, { isLoading: isDisconnecting }] =
    shopsIntegrationsApi.useDisconnnectShopIntegrationMutation();

  useEffect(() => {
    setActiveSection(location.pathname.split('/')[1] as pageSlug);
  }, [location.pathname]);

  const disconnectShop = async () => {
    await disconnnectShopIntegration({
      id: activeIntegrationDetails?.shop_integration_id,
    });
    await dispatch(
      shopsIntegrationsApi.endpoints.getActiveIntegrations.initiate(undefined, {
        forceRefetch: true,
      })
    );
    captureEvent('Integration Disconnected', {
      type: 'Etsy',
      shop_name: activeIntegrationDetails?.shop_name,
      shop_url: activeIntegrationDetails?.shop_url,
    });

    navigate(
      `/integrations?shop-disconnected=${activeIntegrationDetails.shop_name}`
    );
  };

  return (
    <Flex direction={isMobile ? 'column' : 'row'} className="workspace-wrapper">
      <LeftSideMenu activeSection={activeSection} />
      <Flex className="view-wrapper-shop" direction={'column'} gap={'4'}>
        <Helmet>
          <title>Dynamic Mockups | Shop</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Flex
          className="header"
          px={'5'}
          py={'3'}
          align={'center'}
          justify={'between'}
          gap={'4'}
        >
          <Flex>
            <Text
              weight={'regular'}
              size={'5'}
              color="gray"
              onClick={() => navigate('/integrations')}
              className="back"
            >
              Integrations
            </Text>
            <Text weight={'regular'} size={'5'} color="gray" mx={'3'}>
              {'>'}
            </Text>
            <Text weight={'bold'} size={'5'}>
              Etsy
            </Text>
          </Flex>

          <AlertDialog.Root>
            <AlertDialog.Trigger>
              <Button color="red" variant="soft">
                <TrashBin className="icon" width="18px" height="18px" />
                <Text size="1" weight="medium">
                  Disconect
                </Text>
              </Button>
            </AlertDialog.Trigger>
            <AlertDialog.Content style={{ maxWidth: 450 }}>
              <AlertDialog.Title>Disconnect shop</AlertDialog.Title>
              <AlertDialog.Description size="2">
                Are you sure you want to proceed? You will loose the ability to
                sync product mockups automatically.
              </AlertDialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <AlertDialog.Cancel>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Cancel>
                  <Button
                    variant="solid"
                    color="red"
                    onClick={() => disconnectShop()}
                  >
                    Disconnect
                  </Button>
                </AlertDialog.Cancel>
              </Flex>
            </AlertDialog.Content>
          </AlertDialog.Root>
        </Flex>

        <ScrollArea
          className="scroll-area-wrapper"
          type="hover"
          scrollbars="vertical"
          style={{
            height: `calc(100vh - 77px ${showNotificationBar ? '- 50px' : ''})`,
          }}
        >
          <Flex
            className="view-wrapper"
            direction={'column'}
            gap={'3'}
            px={'5'}
            height={'100%'}
          >
            <Flex
              direction={'column'}
              align={'stretch'}
              justify={'between'}
              gap={'4'}
              height={'100%'}
              pb={'4'}
            >
              <Tabs.Root defaultValue="info">
                <Tabs.List>
                  <Tabs.Trigger value="info">Info</Tabs.Trigger>
                </Tabs.List>

                <Box pt="3">
                  <Tabs.Content value="info">
                    <Flex
                      direction={'row'}
                      align={'center'}
                      justify={'start'}
                      width={'100%'}
                      gap={'2'}
                      wrap={'wrap'}
                      className="items-card-wrapper"
                    >
                      <Flex direction={'column'} gap={'2'}>
                        <Flex gap="1">
                          <Text size={'4'} weight={'bold'}>
                            Status:
                          </Text>
                          <Text size={'4'} weight={'bold'}>
                            <Badge color="green">CONNECTED</Badge>
                          </Text>
                        </Flex>
                        <Flex gap="1">
                          <Text size={'4'} weight={'bold'}>
                            Shop name:
                          </Text>
                          <Text size={'4'} weight={'regular'}>
                            {activeIntegrationDetails?.shop_name}
                          </Text>
                        </Flex>
                        <Flex gap="1">
                          <Text size={'4'} weight={'bold'}>
                            Shop URL:
                          </Text>
                          <Text size={'4'} weight={'regular'}>
                            {activeIntegrationDetails?.shop_url}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Tabs.Content>
                </Box>
              </Tabs.Root>
              <Flex direction={'column'} justify={'center'}>
                <Text size="2" weight="regular" align={'center'}>
                  The term 'Etsy' is a trademark of Etsy, Inc. This application
                  uses the Etsy API but is not endorsed or certified by Etsy,
                  Inc.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </ScrollArea>
      </Flex>
    </Flex>
  );
};
