import { Helmet } from 'react-helmet-async';
import './mockup-editor-embed-integrations.scss';
import {
  Flex,
  ScrollArea,
  Text,
  Link,
  Button,
  Dialog,
  TextField,
  Table,
  Tooltip,
  AlertDialog,
  Card,
  Box,
  Tabs,
  Separator,
} from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { mockupEditorEmbedApi } from '@/services/mockupEditorEmbed';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen-linear.svg';
import { toast } from 'react-toastify';
import { ApiKey, MockupEditorIframeIntegration } from '@/redux/slices/types';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import {
  ChatBubbleIcon,
  CodeIcon,
  Cross2Icon,
  FilePlusIcon,
  ImageIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { CodeImplementationExample } from './CodeImplementationExample';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CodeImplementationExampleReact } from './CodeImplementationExamplReact';
import { CodeImplementationExampleVue } from './CodeImplementationExampleVue';
import useImageToBase64 from '@/hooks/useImageToBase64';
import './add-design.scss';
import Chrome from '@uiw/react-color-chrome';
import { IntegrationConfig } from './IntegrationConfig';

interface IFormInput {
  websiteKeyName: string;
  whitelistedDomain: string;
  logo: File;
}

export const MockupEditorEmbedIntegrations = () => {
  const captureEvent = useCaptureEvent();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [codeExampleOpen, setCodeExampleOpen] = useState<boolean>(false);
  const [editedWebsiteKey, setEditedWebsiteKey] =
    useState<MockupEditorIframeIntegration>(null!);
  const [codeWebsiteKey, setCodeWebsiteKey] = useState<string>('');
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const mockupEditorIframeIntegrationsData =
    mockupEditorEmbedApi.useGetMockupEditorIframeIntegrationsQuery();

  const [websiteKeyName, setWebsiteKeyName] = useState<string>('');
  const [whitelistedDomain, setWhitelistedDomain] = useState<string>('');

  const [createMockupEditorIframeIntegration, { isLoading: isCreating }] =
    mockupEditorEmbedApi.useCreateMockupEditorIframeIntegrationMutation();
  const [deleteMockupEditorIframeIntegration, { isLoading: isDeleting }] =
    mockupEditorEmbedApi.useDeleteMockupEditorIframeIntegrationMutation();
  const [updateMockupEditorIframeIntegration, { isLoading: isUpdating }] =
    mockupEditorEmbedApi.useUpdateMockupEditorIframeIntegrationMutation();
  const [
    useUpdateMockupEditorIframeIntegrationConfig,
    { isLoading: isConfigLoading },
  ] =
    mockupEditorEmbedApi.useUpdateMockupEditorIframeIntegrationConfigMutation();

  useEffect(() => {
    resetCreateDialog();
    // removeAddedLogo();
  }, [createDialogOpen]);

  const deleteKeyAction = async (item: MockupEditorIframeIntegration) => {
    try {
      await deleteMockupEditorIframeIntegration({ id: item.id });
      captureEvent('Mockup editor iframe - User deleted Website Key', {
        integration_name: item.integration_name,
        whitelisted_domain: item.whitelisted_domain,
      });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const {
    register: registerCreateDialog,
    handleSubmit: handleSubmitCreateWebsiteKey,
    formState: { errors: createErrors },
    reset: resetCreateDialog,
  } = useForm<IFormInput>();

  const onSubmitCreateWebsiteKey: SubmitHandler<IFormInput> = (data) =>
    createIntegration(data);

  const createIntegration = async (data: IFormInput) => {
    try {
      const createdIntegration = await createMockupEditorIframeIntegration({
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
        config: {
          logo: editedWebsiteKey.config?.logo || null,
          color: editedWebsiteKey.config?.color || '',
          whitelabel: editedWebsiteKey.config?.whitelabel || false,
          dark_mode: editedWebsiteKey.config?.dark_mode || false,
        },
      }).unwrap();
      captureEvent('Mockup editor iframe - User created Website Key', {
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
        $set: { website_key_created: true },
      });
      setCodeWebsiteKey(createdIntegration.data.website_key);
      setEditedWebsiteKey(createdIntegration.data);
      setCodeExampleOpen(true);
      setCreateDialogOpen(false);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const {
    register: registerUpdateDialog,
    handleSubmit: handleSubmitUpdateWebsiteKey,
    formState: { errors: updateErrors },
    reset: resetUpdateDialog,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      websiteKeyName: editedWebsiteKey?.integration_name,
      whitelistedDomain: editedWebsiteKey?.whitelisted_domain,
    },
  });

  useEffect(() => {
    setValue('websiteKeyName', editedWebsiteKey?.integration_name);
    setValue('whitelistedDomain', editedWebsiteKey?.whitelisted_domain);
  }, [editedWebsiteKey]);

  const onSubmitUpdateWebsiteKey: SubmitHandler<IFormInput> = (data) =>
    updateIntegration(data);

  const updateIntegration = async (data: IFormInput) => {
    try {
      await updateMockupEditorIframeIntegration({
        id: editedWebsiteKey.id,
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
      });
      const base64PrefixRegex = /^data:(\w+\/[\w+.-]+)?;base64,/;
      await useUpdateMockupEditorIframeIntegrationConfig({
        mockup_editor_iframe_integration_id: editedWebsiteKey.id,
        config: {
          logo: editedWebsiteKey.config?.logo?.match(base64PrefixRegex)
            ? editedWebsiteKey.config?.logo
            : editedWebsiteKey.config?.logo === null
            ? null
            : undefined,
          color: editedWebsiteKey.config?.color || '',
          whitelabel: editedWebsiteKey.config?.whitelabel || false,
          dark_mode: editedWebsiteKey.config?.dark_mode || false,
        },
      });
      captureEvent('Mockup editor iframe - User edited Website Key', {
        integration_name: data.websiteKeyName,
        whitelisted_domain: data.whitelistedDomain,
      });
      setEditDialogOpen(false);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'website_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  return (
    <Flex
      className="view-wrapper-embed-mockup-edditor"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | Embed Mockup Editor</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex className="header" px={'5'} py={'3'} align={'center'}>
        <Text weight={'bold'} size={'5'}>
          Embed Mockup Editor
        </Text>
      </Flex>

      <ScrollArea
        className="scroll-area-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{
          height: `calc(100vh - 77px - 50px ${
            showNotificationBar ? '- 50px' : ''
          })`,
        }}
      >
        <Flex className="view-wrapper" direction={'column'} gap={'3'} px={'5'}>
          <Flex direction={'column'} align={'start'} gap={'4'}>
            <Flex
              direction={'row'}
              align={'center'}
              justify={'between'}
              width={'100%'}
              gap={'2'}
              className="items-card-wrapper"
            >
              <Card className="item-card" size={'5'}>
                <Flex direction={'column'}>
                  <Text className="title" size={'3'} weight={'bold'} mb={'5'}>
                    Set up Mockup Editor
                  </Text>
                  <Text size={'2'}>
                    The Mockup Editor iFrame enables you to embed a fully
                    functional mockup editor directly into your web or mobile
                    app. This eliminates the need for extensive coding, allowing
                    you to implement it seamlessly with just a few lines of
                    code.
                  </Text>

                  <Flex
                    className="buttons-wrapper"
                    direction={'row'}
                    align={'center'}
                    gap={'3'}
                  >
                    <Dialog.Root
                      open={createDialogOpen}
                      onOpenChange={setCreateDialogOpen}
                    >
                      <Dialog.Trigger>
                        <Button
                          color="gray"
                          variant="outline"
                          disabled={isCreating}
                          className="action"
                        >
                          <PlusIcon className="icon" />
                          <Text size="1" weight="medium">
                            Create new Website Key
                          </Text>
                        </Button>
                      </Dialog.Trigger>

                      <Dialog.Content
                        aria-describedby={undefined}
                        style={{ maxWidth: 450 }}
                      >
                        <Dialog.Title>Create Website Key</Dialog.Title>
                        <Dialog.Description size="2" mb="4">
                          Name your Website for which you want to create an
                          website key for.
                        </Dialog.Description>
                        <form
                          onSubmit={handleSubmitCreateWebsiteKey(
                            onSubmitCreateWebsiteKey
                          )}
                        >
                          <Flex direction="column" gap="3">
                            <Flex direction={'column'}>
                              <label>
                                <Text size={'1'}>Website name</Text>
                                <TextField.Root
                                  {...registerCreateDialog('websiteKeyName', {
                                    required: {
                                      value: true,
                                      message: 'Website name field is required',
                                    },
                                  })}
                                  placeholder="Dynamic Mockups"
                                />
                              </label>
                              {createErrors.websiteKeyName && (
                                <Text role="alert" size={'1'} color="red">
                                  {createErrors.websiteKeyName.message}
                                </Text>
                              )}
                            </Flex>
                            <Flex direction={'column'}>
                              <label>
                                <Text size={'1'}>Whitelisted domain</Text>
                                <TextField.Root
                                  {...registerCreateDialog(
                                    'whitelistedDomain',
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          'Whitelisted domain field is required',
                                      },
                                      pattern: {
                                        value:
                                          /^(https?:\/\/)?((localhost(:\d+)?|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))(\/.*)?$/,
                                        message: `Entered value is not valid domain format`,
                                      },
                                    }
                                  )}
                                  placeholder="https://dynamicmockups.com"
                                />
                              </label>
                              {createErrors.whitelistedDomain && (
                                <Text role="alert" size={'1'} color="red">
                                  {createErrors.whitelistedDomain.message}
                                </Text>
                              )}
                            </Flex>

                            <IntegrationConfig
                              setData={(data) => {
                                setEditedWebsiteKey((prev) => ({
                                  ...prev,
                                  ...data,
                                }));
                              }}
                            />
                          </Flex>
                          <Flex gap="3" mt="4" justify="end">
                            <Dialog.Close>
                              <Button variant="soft" color="gray">
                                Cancel
                              </Button>
                            </Dialog.Close>
                            <Button
                              event-tracker-id="create-website-key"
                              variant="solid"
                              color="blue"
                              type="submit"
                              loading={isCreating}
                            >
                              Create
                            </Button>
                          </Flex>
                        </form>
                      </Dialog.Content>
                    </Dialog.Root>
                    <Link
                      href="https://docs.dynamicmockups.com/mockup-editor-sdk/embed-editor"
                      target="_blank"
                    >
                      <Button
                        color="blue"
                        variant="solid"
                        onClick={() =>
                          captureEvent('Embed Mockup Editor Docs visited')
                        }
                      >
                        Read Mockup Editor Docs
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </Card>
            </Flex>

            <Dialog.Root open={editDialogOpen} onOpenChange={setEditDialogOpen}>
              <Dialog.Content
                aria-describedby={undefined}
                style={{ maxWidth: 450 }}
              >
                <Dialog.Title>Edit Website Key</Dialog.Title>
                <Dialog.Description size="2" mb="4">
                  Edit website name and whitelisted domain.
                </Dialog.Description>
                <form
                  onSubmit={handleSubmitUpdateWebsiteKey(
                    onSubmitUpdateWebsiteKey
                  )}
                >
                  <Flex direction="column" gap="3" my={'4'}>
                    <Flex direction={'column'}>
                      <label>
                        <Text size={'1'}>Website name</Text>
                        <TextField.Root
                          {...registerUpdateDialog('websiteKeyName', {
                            required: {
                              value: true,
                              message: 'Website name field is required',
                            },
                          })}
                          placeholder="Dynamic Mockups"
                        />
                      </label>
                      {updateErrors.websiteKeyName && (
                        <Text role="alert" size={'1'} color="red">
                          {updateErrors.websiteKeyName.message}
                        </Text>
                      )}
                    </Flex>
                    <Flex direction={'column'}>
                      <label>
                        <Text size={'1'}>Whitelisted domain</Text>
                        <TextField.Root
                          {...registerUpdateDialog('whitelistedDomain', {
                            required: {
                              value: true,
                              message: 'Whitelisted domain field is required',
                            },
                            pattern: {
                              value:
                                /^(https?:\/\/)?((localhost(:\d+)?|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))(\/.*)?$/,
                              message: `Entered value is not valid domain format`,
                            },
                          })}
                          placeholder="https://dynamicmockups.com"
                        />
                      </label>
                      {updateErrors.whitelistedDomain && (
                        <Text role="alert" size={'1'} color="red">
                          {updateErrors.whitelistedDomain.message}
                        </Text>
                      )}
                    </Flex>

                    <IntegrationConfig
                      editedIntegration={{
                        ...editedWebsiteKey,
                      }}
                      setData={(data) => {
                        setEditedWebsiteKey((prev) => ({
                          ...prev,
                          ...data,
                        }));
                      }}
                    />
                  </Flex>

                  <Flex gap="3" justify="end">
                    <Dialog.Close>
                      <Button variant="soft" color="gray">
                        Cancel
                      </Button>
                    </Dialog.Close>
                    <Button
                      variant="solid"
                      color="blue"
                      type="submit"
                      loading={isUpdating || isConfigLoading}
                    >
                      Update
                    </Button>
                  </Flex>
                </form>
              </Dialog.Content>
            </Dialog.Root>
            <Dialog.Root
              open={codeExampleOpen}
              onOpenChange={(open) => {
                setCodeExampleOpen(open);
              }}
            >
              <Dialog.Content
                aria-describedby={undefined}
                width={'100%'}
                maxWidth={'992px'}
                minHeight={'706px'}
              >
                <Dialog.Title>Implementation code example</Dialog.Title>
                <Dialog.Description></Dialog.Description>

                <Flex direction="column" gap="3" my={'4'}>
                  <Tabs.Root
                    defaultValue="javascript"
                    onValueChange={(value) => {
                      console.log(value);
                      captureEvent(
                        `Mockup editor iframe - User opened embed code example`,
                        {
                          type: value,
                          integration_name: editedWebsiteKey.integration_name,
                          whitelisted_domain:
                            editedWebsiteKey.whitelisted_domain,
                        }
                      );
                    }}
                  >
                    <Tabs.List>
                      <Tabs.Trigger value="javascript">Javascript</Tabs.Trigger>
                      <Tabs.Trigger value="react">ReactJs</Tabs.Trigger>
                      <Tabs.Trigger value="vue">VueJs</Tabs.Trigger>
                    </Tabs.List>

                    <ScrollArea
                      className="scroll-area-wrapper"
                      type="hover"
                      scrollbars="vertical"
                      style={{
                        height: 'calc(68vh)',
                        maxHeight: '100vh',
                        position: 'relative',
                      }}
                    >
                      <Box pt="3">
                        <Tabs.Content value="javascript">
                          <CodeImplementationExample
                            websiteKey={codeWebsiteKey}
                          />
                        </Tabs.Content>

                        <Tabs.Content value="react">
                          <CodeImplementationExampleReact
                            websiteKey={codeWebsiteKey}
                          />
                        </Tabs.Content>

                        <Tabs.Content value="vue">
                          <CodeImplementationExampleVue
                            websiteKey={codeWebsiteKey}
                          />
                        </Tabs.Content>
                      </Box>
                    </ScrollArea>
                  </Tabs.Root>
                </Flex>

                <Flex gap="3" justify="end">
                  <Dialog.Close>
                    <Button variant="solid" color="blue">
                      Done
                    </Button>
                  </Dialog.Close>
                </Flex>
              </Dialog.Content>
            </Dialog.Root>
            <Flex
              direction={'column'}
              style={{ width: '100%' }}
              className="table-wrapper"
            >
              <Table.Root variant="surface" size="1" style={{ width: '100%' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      Website name
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      Whitelisted domain
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Website key</Table.ColumnHeaderCell>
                    {/* <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell> */}
                    <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>
                {(mockupEditorIframeIntegrationsData.data?.data || []).length >
                  0 && (
                  <Table.Body className="keys-table-wrapper">
                    {mockupEditorIframeIntegrationsData.data?.data.map(
                      (item) => (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            <Flex>
                              <Text
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                {item.integration_name}
                              </Text>
                            </Flex>
                          </Table.Cell>

                          <Table.Cell>
                            <Flex gap={'1'}>
                              <Link
                                href={item.whitelisted_domain}
                                target="_blank"
                              >
                                <Text
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  {item.whitelisted_domain}
                                </Text>
                              </Link>
                            </Flex>
                          </Table.Cell>
                          <Table.Cell>
                            <Flex>
                              <Text
                                style={{
                                  display: 'inline-block',
                                }}
                                className="key-text"
                                mr={'1'}
                              >
                                {item.website_key.toString().substring(0, 3) +
                                  (isMobile ? '*' : '******') +
                                  item.website_key
                                    .toString()
                                    .substring(
                                      item.website_key.length - 3,
                                      item.website_key.length
                                    )}
                              </Text>
                              <Tooltip content="Copy Website Key">
                                <Button
                                  event-tracker-id="copy-website-key"
                                  size={'1'}
                                  variant="soft"
                                  color="gray"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.website_key
                                    );
                                    captureEvent(
                                      'Mockup editor iframe - User copied Website Key',
                                      {
                                        integration_name: item.integration_name,
                                        whitelisted_domain:
                                          item.whitelisted_domain,
                                      }
                                    );
                                    toast.success(
                                      'Website Key copied to clipboard.',
                                      {
                                        toastId: 'website_key_action_error',
                                        position: 'bottom-right',
                                        autoClose: 1500,
                                      }
                                    );
                                  }}
                                >
                                  <CopyIcon
                                    className="icon"
                                    width={'14px'}
                                    height={'14px'}
                                  />
                                </Button>
                              </Tooltip>
                            </Flex>
                          </Table.Cell>
                          {/* <Table.Cell>
                          <Flex>
                            <Text
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              19/02/24
                            </Text>
                          </Flex>
                        </Table.Cell> */}
                          <Table.Cell className="actions-buttons">
                            <Tooltip content="View embedding code">
                              <Button
                                event-tracker-id="view-embedding-code"
                                size={'1'}
                                variant="soft"
                                color="gray"
                                onClick={() => {
                                  captureEvent(
                                    'Mockup editor iframe - User visited embed code example',
                                    {
                                      integration_name: item.integration_name,
                                      whitelisted_domain:
                                        item.whitelisted_domain,
                                    }
                                  );
                                  setEditedWebsiteKey(item);
                                  setCodeExampleOpen(true);
                                  setCodeWebsiteKey(item.website_key);
                                }}
                              >
                                <CodeIcon width={'16px'} height={'16px'} />
                              </Button>
                            </Tooltip>
                            <Tooltip content="Edit key">
                              <Button
                                event-tracker-id="edit-website-key"
                                size={'1'}
                                variant="soft"
                                color="gray"
                                mx={'2'}
                                onClick={() => {
                                  setEditedWebsiteKey(item);
                                  setEditDialogOpen(true);
                                }}
                              >
                                <PenIcon
                                  className="icon"
                                  width={'14px'}
                                  height={'14px'}
                                />
                              </Button>
                            </Tooltip>
                            <AlertDialog.Root>
                              <Tooltip content="Delete key">
                                <AlertDialog.Trigger>
                                  <Button
                                    size={'1'}
                                    variant="soft"
                                    color="gray"
                                  >
                                    <TrashBin
                                      className="icon"
                                      width={'14px'}
                                      height={'14px'}
                                    />
                                  </Button>
                                </AlertDialog.Trigger>
                              </Tooltip>
                              <AlertDialog.Content style={{ maxWidth: 600 }}>
                                <AlertDialog.Title>
                                  Delete Website key
                                </AlertDialog.Title>
                                <AlertDialog.Description size="2">
                                  Are you sure you want to delete Website Key
                                  used for '{item.integration_name}'?
                                </AlertDialog.Description>

                                <Flex gap="3" mt="4" justify="end">
                                  <AlertDialog.Cancel>
                                    <Button variant="soft" color="gray">
                                      Cancel
                                    </Button>
                                  </AlertDialog.Cancel>
                                  <AlertDialog.Cancel>
                                    <Button
                                      event-tracker-id="delete-website-key"
                                      variant="solid"
                                      color="red"
                                      onClick={() => deleteKeyAction(item)}
                                    >
                                      Delete
                                    </Button>
                                  </AlertDialog.Cancel>
                                </Flex>
                              </AlertDialog.Content>
                            </AlertDialog.Root>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                )}
              </Table.Root>
              {(mockupEditorIframeIntegrationsData.data?.data || []).length ===
                0 && (
                <Flex
                  align={'center'}
                  justify={'center'}
                  p={'3'}
                  style={{ width: '100%' }}
                >
                  <Text size={'1'}>There are no active Website Keys</Text>
                </Flex>
              )}
            </Flex>

            <Flex direction={'column'} gap={'4'} width="100%"></Flex>
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

