import './view-wrapper.scss';
import { Grid, ScrollArea } from '@radix-ui/themes';
import { SingleTemplate } from './SingleTemplate';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { psdManagementApi } from '@/services/psdManagement';
import { useAppSelector } from '@/hooks';

interface IViewWrapper {
  categoryId: number;
}

export const ViewWrapper = ({ categoryId = 0 }: IViewWrapper) => {
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const subscriptionTypes = psdManagementApi.useGetSubscriptionTypesQuery();
  const publicCatalogs = workspaceApi.useGetPublicCatalogsQuery(
    {
      categoryId,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{
        height: `calc(100vh - 125px ${showNotificationBar ? '- 50px' : ''})`,
      }}
    >
      <Grid className="view-wrapper" gap={'2'} px={'5'}>
        {publicCatalogs.isLoading
          ? [...Array(12)].map((item, index) => (
              <Skeleton key={`${index}-item`} className="single-template" />
            ))
          : publicCatalogs.data?.data
              ?.slice(0)
              ?.reverse()
              ?.map((catalog) => (
                <SingleTemplate
                  key={catalog.id}
                  catalog={catalog}
                  subscriptionTypes={subscriptionTypes.data?.data || []}
                />
              ))}
      </Grid>
    </ScrollArea>
  );
};
