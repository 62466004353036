import { Helmet } from 'react-helmet-async';
import './products.scss';
import { Button, Flex, ScrollArea, Text } from '@radix-ui/themes';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download-minimalistic-bold.svg';
import { ProductsTable } from './components/ProductsTable';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { shopsProductsApi } from '@/services/shopsProducts';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import { resetListingsState } from '@/redux/slices/shopsProducts';
import posthog from 'posthog-js';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const Products = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useParams();
  const captureEvent = useCaptureEvent();
  const { showNotificationBar } = useAppSelector((state) => state.appReducer);
  const { listings, isSyncingListings } = useAppSelector(
    (state) => state.shopsProductsReducer
  );
  const { activeIntegrationDetails } = useAppSelector(
    (state) => state.shopsIntegrations
  );

  shopsIntegrationsApi.useGetActiveIntegrationDetailsQuery(
    {
      id: parseInt(shopId || ''),
    },
    {
      skip: !shopId,
      refetchOnMountOrArgChange: true,
    }
  );

  shopsIntegrationsApi.useGetActiveIntegrationsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  shopsProductsApi.useGetListingsQuery(
    { shop_integration_platform_id: parseInt(shopId as string) },
    {
      skip: !shopId,
      // pollingInterval: listings.data.products.length === 0 ? 1000 : 0,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    dispatch(resetListingsState());
  }, []);

  const syncProductListingsAction = async () => {
    captureEvent('Product Listing Fetch Initiated', {
      type: 'Etsy',
      shop_name: activeIntegrationDetails?.shop_name,
      shop_url: activeIntegrationDetails?.shop_url,
    });
    try {
      await dispatch(
        shopsProductsApi.endpoints.syncListings.initiate({
          shop_integration_platform_id: parseInt(shopId as string),
        })
      ).unwrap();
      const listings = await dispatch(
        shopsProductsApi.endpoints.getListings.initiate(
          {
            shop_integration_platform_id: parseInt(shopId as string),
          },
          { forceRefetch: true }
        )
      ).unwrap();
      captureEvent('Product Listing Fetch Successful', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        products_count: listings.data.listings.length,
      });
    } catch (error) {
      captureEvent('Product Listing Fetch Failed', {
        type: 'Etsy',
        shop_name: activeIntegrationDetails?.shop_name,
        shop_url: activeIntegrationDetails?.shop_url,
        error_message: error,
      });
    }
  };

  return (
    <Flex className="view-wrapper-shops" direction={'column'} gap={'4'}>
      <Helmet>
        <title>Dynamic Mockups | Products</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        px={'5'}
        py={'3'}
        align={'center'}
        justify={'between'}
        gap={'4'}
      >
        <Text weight={'bold'} size={'5'}>
          Products
        </Text>
        {listings.data.lastSync && (
          <Flex align={'center'} gap={'2'}>
            <Text size={'1'} color="gray">
              {listings.data.lastSync && (
                <i>
                  Last synced on {listings.data.lastSync.date} at{' '}
                  {listings.data.lastSync.time +
                    ' ' +
                    listings.data.lastSync.timezone}
                </i>
              )}
            </Text>
            <Button
              color="gray"
              variant="outline"
              onClick={() => syncProductListingsAction()}
              loading={listings.isLoading || isSyncingListings}
            >
              <DownloadIcon className="icon black" />
              <Text size="1" weight="medium">
                Fetch Product Listings
              </Text>
            </Button>
          </Flex>
        )}
      </Flex>

      <ScrollArea
        className="scroll-area-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{
          height: `calc(100vh - 77px ${showNotificationBar ? '- 50px' : ''})`,
        }}
      >
        {listings.isLoading ? (
          <Flex
            className="view-wrapper"
            direction={'column'}
            height={'100%'}
            gap={'3'}
            px={'5'}
          >
            <Skeleton height={'50px'}></Skeleton>
          </Flex>
        ) : (
          <Flex
            className="view-wrapper"
            direction={'column'}
            height={'100%'}
            gap={'3'}
            px={'5'}
          >
            <ProductsTable />
          </Flex>
        )}
      </ScrollArea>
    </Flex>
  );
};
